<template>
    <div class="carousel-form">

        <div class="form-container">
            <div class="form-body">

                <div class="form-item">
                    <div class="label">
                        <div class="label-container">
                            <span>标题</span>
                            <em>*</em>
                        </div>
                    </div>
                    <div class="item">
                        <n-input placeholder="请输入轮播标题" v-model:value="formData.title" />
                    </div>
                </div>

                <div class="form-item">
                    <div class="label">
                        <div class="label-container">
                            <span>链接</span>
                        </div>
                    </div>
                    <div class="item">
                        <n-input placeholder="请输入跳转链接" v-model:value="formData.url" />
                    </div>
                </div>

                <div class="form-item">
                    <div class="label">
                        <div class="label-container">
                            <span>图片</span>
                            <em>*</em>
                        </div>
                    </div>
                    <div class="item">
                        <n-upload v-model:src="formData.img" width="auto" :height="400" />
                    </div>
                </div>

            </div>
        </div>

    </div>

    <div class="bottom-tools-box">
        <n-button class="tool-btn" size="large" @click="reset">重置</n-button>
        <n-button 
            class="tool-btn" 
            size="large" 
            type="primary" 
            :loading="btnLoading"
            :disabled="formData.title === '' || formData.img === ''"
            @click="submit"
        >确定</n-button>
    </div>    
</template>

<script>

export default {
    name: 'CarouselForm',
    props: {
        data:{
            type: Object,
            default: {}
        },
        btnLoading: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            formData:{
                title: '',
                url: '',
                img: ''
            },
            
        }
    },
    watch:{
        'data':{
            handler(){
                this.formData = this.data;
            },
            deep: true
        }
    },
    emits: ['submit'],
    methods: {
        reset(){},
        submit(){
            this.$emit('submit', this.formData);
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'form-style';
.carousel-form{
    // border-radius: $radius;
    // background-color: $white;
    // box-shadow: $shadow-default;
    .form-container{
        margin: 0;
    }
}
</style>